import { MY_DRAWN_AREA, MY_TRAVEL_TIME_AREA } from '@propertypal/shared/src/constants/search';
import { RecentSearch } from '@propertypal/shared/src/hooks/useRecentSearches';
import useSearchState from '@propertypal/shared/src/hooks/useSearchState';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { Filters } from '@propertypal/shared/src/utils/search/getNlpFilters';
import { Suggestion } from '@propertypal/shared/src/utils/search/groupTokensByType';
import Button from '@propertypal/web-ui/src/buttons/Button';
import SearchInput from '@propertypal/web-ui/src/inputs/SearchInput';
import 'intersection-observer';
import { useRouter } from 'next/router';
import React, { CSSProperties, FormEvent, FunctionComponent, useRef, useState } from 'react';
import getCurrentLocation from '../../services/location';
import { Container, SearchButtons, SearchForm, SearchInputContainer } from './HomeSearchBar.style';
import SearchPreviewModal from './SearchPreviewModal';

interface Props {
  containerStyle?: CSSProperties;
  submitText: string;
  onSubmit: (baseUrl: string, searchText: string, filters: Filters) => void;
  extraNlpParams?: string;
  placeholder?: string;
  showInstantValuation?: boolean;
  showTravelPreview?: boolean;
  recentSearches?: RecentSearch[];
  showSaleTypeOptions?: boolean;
  removeRecentSearch?: (search: RecentSearch) => void;
}

const HomeSearchBar: FunctionComponent<Props> = (props) => {
  const { handleChangeText, nlpData, searchText, getLocation, fetchingLocation } = useSearchState(props.extraNlpParams);
  const [showSearchPreview, setShowSearchPreview] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();

  const handleLocationClick = (position: 'suggestion' | 'inline') => {
    trackGaEvent('current_location_click', {
      page: 'HP',
      location: 'Search Box',
      position,
    });
    return getLocation(getCurrentLocation, true);
  };

  const handleTravelTimeClick = (position: 'suggestion' | 'inline') => {
    trackGaEvent('travel_time_click', {
      page: 'HP',
      location: 'Search Box',
      position,
    });
    setShowSearchPreview('travelTime');
  };

  const handleDrawSearchClick = (position: 'suggestion' | 'inline') => {
    trackGaEvent('draw_click', {
      page: 'HP',
      location: 'Search Box',
      position,
    });
    setShowSearchPreview('drawSearch');
  };

  const getLocationText = async () => {
    const position = await getLocation(getCurrentLocation, false);

    if (position.lat) {
      return position;
    }

    return {};
  };

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    const position = await getLocationText();

    props.onSubmit('', searchText, {
      ...position,
    });
  };

  const handleSecondarySubmit = (category: string, saleType: string) => async () => {
    const position = await getLocationText();

    props.onSubmit('', searchText, {
      category,
      saleType,
      ...position,
    });
  };

  const handleSuggestionClick = (token: Suggestion) => {
    if (token.url) {
      router.push(token.url);
    } else {
      handleChangeText(token.text);
    }
  };

  const handlePreviewSubmit = (polyIds: number[], category: string, saleType: string) => {
    props.onSubmit('/map', showSearchPreview === 'travelTime' ? MY_TRAVEL_TIME_AREA : MY_DRAWN_AREA, {
      category,
      saleType,
      polyIds,
    });
  };

  return (
    <Container style={props.containerStyle}>
      <SearchForm action="" onSubmit={handleSubmit}>
        <SearchInputContainer>
          <SearchInput
            inputRef={inputRef}
            disableSavedSearches
            onChangeText={handleChangeText}
            loading={fetchingLocation}
            placeholder={props.placeholder}
            suggestionsOffset={props.showInstantValuation ? 115 : 80}
            suggestionsLeftOffset={6}
            value={searchText}
            nlpData={nlpData}
            onLocationClick={handleLocationClick}
            onSuggestionClick={handleSuggestionClick}
            onTravelTimeClick={props.showTravelPreview ? handleTravelTimeClick : undefined}
            onDrawSearchClick={props.showTravelPreview ? handleDrawSearchClick : undefined}
            isHomePage
            recentSearches={props.recentSearches}
            onRecentSearchClick={(s, f) => props.onSubmit('', s, f)}
            onRemoveRecentSearch={props.removeRecentSearch}
          />
        </SearchInputContainer>

        <SearchButtons multiButtons={props.showSaleTypeOptions}>
          <Button containerStyle={{ height: '54px' }} testID="forSaleButton" type="submit">
            {props.submitText}
          </Button>

          {props.showSaleTypeOptions && (
            <Button
              containerStyle={{ height: '54px' }}
              testID="forRentButton"
              onClick={handleSecondarySubmit('residential', 'rent')}
              type="button"
            >
              For Rent
            </Button>
          )}

          {props.showSaleTypeOptions && (
            <Button
              containerStyle={{ height: '54px' }}
              testID="newHomesButton"
              onClick={handleSecondarySubmit('newhomes', 'sale')}
              type="button"
            >
              New Homes
            </Button>
          )}
        </SearchButtons>
      </SearchForm>

      {showSearchPreview && (
        <SearchPreviewModal
          show={showSearchPreview}
          onClose={() => setShowSearchPreview('')}
          onSubmit={handlePreviewSubmit}
          showSaleTypeOptions={props.showSaleTypeOptions}
        />
      )}
    </Container>
  );
};

export default HomeSearchBar;
