import { useEffect, useState } from 'react';
import { getItem, setItem } from '../services/local.storage';
import { PropertySearchResult } from '../types/propertySearch';
import { Filters } from '../utils/search/getNlpFilters';

export interface RecentSearch {
  description: PropertySearchResult['description'];
  text: string;
  filters: Filters;
}

const RECENT_SEARCH_KEY = 'recentSearches';

const useRecentSearches = (activeSearch?: PropertySearchResult['description']) => {
  const [recentSearches, setRecentSearches] = useState<RecentSearch[]>([]);

  const getRecentSearches = async () => {
    const localSearches = await getItem<RecentSearch[]>(RECENT_SEARCH_KEY);

    if (localSearches) {
      setRecentSearches(localSearches);
    }
  };

  const addRecentSearch = (search: RecentSearch) => {
    const index = recentSearches.findIndex((s) => s.description.complete === search.description.complete);

    if (index >= 0) {
      const searches = [...recentSearches];
      searches.unshift(searches.splice(index, 1)[0]);
      setRecentSearches(searches);
      setItem(RECENT_SEARCH_KEY, searches);
    } else {
      const searches = [search, ...recentSearches];

      if (searches.length > 4) searches.pop();

      setRecentSearches(searches);
      setItem(RECENT_SEARCH_KEY, searches);
    }
  };

  const removeRecentSearch = (search: RecentSearch) => {
    const searches = recentSearches.filter((s) => s.description.complete !== search.description.complete);

    setRecentSearches(searches);
    setItem(RECENT_SEARCH_KEY, searches);
  };

  useEffect(() => {
    getRecentSearches();
  }, []);

  return {
    recentSearches: recentSearches.filter((s) => s.description.complete !== activeSearch?.complete).slice(0, 3),
    addRecentSearch,
    removeRecentSearch,
  };
};

export default useRecentSearches;
