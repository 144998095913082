import { laptopMediumMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';
import { Text } from '../typography';

export const Container = styled.div<{ active: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(53, 53, 53, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: all 1s;
  z-index: 3;

  ${(props) =>
    props.active &&
    `
    visibility: visible;
    opacity: 1;
  `}
`;

export const Content = styled.div<{ $hasShowHome?: boolean; $column?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  padding: 5px 10px;

  &:hover p {
    color: ${(props) => props.theme.backgroundLight};
  }
  &:hover span {
    color: ${(props) => props.theme.backgroundLight};
  }
  &:hover strong {
    color: ${(props) => props.theme.backgroundLight};
  }

  ${(props) =>
    props.$hasShowHome &&
    `
    @media (max-width: 834px) {
      display: none;
    }
  `}

  ${(props) =>
    props.$column &&
    `
    @media (${mobileXXLargeMax}) {
      flex-direction: column;
    }
    
    p:first-child {
      margin-top: 5px;
    }
    
    p {
      text-align:center;
    }
  `}
`;

export const CustomText = styled(Text)`
  display: contents;

  @media screen and (${laptopMediumMax}) {
    display: none;
  }
`;

export const Address = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MobileText = styled.div`
  display: none;
  overflow: hidden;

  p {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (${laptopMediumMax}) {
    display: flex;
    flex-direction: column;
  }

  @media (${mobileXXLargeMax}) {
    flex: 1;
    align-items: flex-start;
  }
`;

export const CustomLink = styled(Link)<{ $hasShowHome?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ $hasShowHome }) =>
    $hasShowHome &&
    `
    justify-content: space-between;
    
    @media (max-width: 834px) {
      justify-content: center;
    }
  `}

  &:hover p {
    color: ${(props) => props.theme.backgroundLight};
  }
  &:hover span {
    color: ${(props) => props.theme.backgroundLight};
  }
  &:hover strong {
    color: ${(props) => props.theme.backgroundLight};
  }
`;

export const ImageWrapper = styled.div`
  height: 30px;
  align-self: center;

  img {
    margin-right: 10px;
  }
`;
