import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import { SearchInputIcon, Container as SearchInput } from '@propertypal/web-ui/src/inputs/SearchInput.style';
import { Input as TextInputInput } from '@propertypal/web-ui/src/inputs/TextInput.style';
import styled from 'styled-components';

export const BREAKPOINT = '780px';

export const Container = styled.div`
  position: relative;
  z-index: 5;
  max-width: 760px;
  width: 100%;
  margin: 70px 0 40px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 6px;

  @media (max-width: ${BREAKPOINT}) {
    margin-top: 60px;
    width: calc(100% - 30px);
  }
`;

export const SearchForm = styled.form<{ instantVal?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
  }

  ${TextInputInput} {
    height: 54px;

    &::placeholder {
      color: ${({ theme }) => theme.backgroundLight};
    }

    &::selection {
      color: ${({ theme }) => theme.white};
      background: ${({ theme }) => theme.primary};
    }
  }
`;

export const SearchInputContainer = styled.div`
  flex: 1;

  @media (${mobileXXLargeMax}) {
    ${SearchInput} {
      padding-left: 15px;
    }

    ${SearchInputIcon} {
      display: block;
    }
  }
`;

export const SearchButtons = styled.div<{ multiButtons?: boolean }>`
  display: flex;
  flex-basis: ${(props) => (props.multiButtons ? '380px;' : '240px')};

  & > button {
    flex: 1;
    margin-left: 6px;
  }

  @media (max-width: ${BREAKPOINT}) {
    position: absolute;
    bottom: -60px;
    width: 100%;
    left: 0;

    & > button:first-child {
      margin-left: 0;
    }
  }
`;
