/* eslint-disable react/no-array-index-key */
import { SuperFeatureProperty } from '@propertypal/shared/src/types/superFeature';
import React, { Dispatch, FunctionComponent, useEffect, useRef, useState, MutableRefObject, ReactNode } from 'react';
import ImageInfoBar from './ImageInfoBar';
import { Container, Content, ImageBox } from './ImageSlideshow.style';

interface Props {
  images: string[];
  highResImages?: string[];
  slideDuration: number;
  superFeatureProperties: SuperFeatureProperty[];
  highResThreshold?: number;
  children: ReactNode;
}

const animateNextSlide =
  (
    lastSlide: number,
    setActiveIndex: Dispatch<number>,
    images: string[],
    slideDuration: number,
    timeout: MutableRefObject<any>,
  ) =>
  () => {
    const nextSlide = lastSlide + 1 < images.length ? lastSlide + 1 : 0;

    setActiveIndex(nextSlide);

    timeout.current = setTimeout(
      animateNextSlide(nextSlide, setActiveIndex, images, slideDuration, timeout),
      slideDuration,
    );
  };

const clearCurrentTimeout = (timeout: MutableRefObject<any>) => {
  if (timeout.current) {
    clearTimeout(timeout.current);
  }
};

const ImageSlideshow: FunctionComponent<Props> = (props) => {
  const timeout = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const lastIndex = activeIndex - 1 < 0 ? props.images.length - 1 : activeIndex - 1;
  const nextIndex = activeIndex + 1 >= props.images.length ? 0 : activeIndex + 1;

  useEffect(() => {
    clearCurrentTimeout(timeout);

    timeout.current = setTimeout(
      animateNextSlide(activeIndex, setActiveIndex, props.images, props.slideDuration, timeout),
      props.slideDuration,
    );

    return () => clearCurrentTimeout(timeout);
  }, [props.images]);

  return (
    <Container>
      <Content>
        <ImageBox
          key={lastIndex}
          active={false}
          data-testid="slide-image"
          src={props.images[lastIndex]}
          sizes="(max-width: 1200px) 1024px, 1600px"
          alt={props.superFeatureProperties[lastIndex]?.displayAddress}
          srcSet={
            props.highResImages
              ? `${props.images[lastIndex]} 1024w, ${props.highResImages[lastIndex]} ${props.highResThreshold}w`
              : undefined
          }
        />

        <ImageBox
          key={activeIndex}
          active
          data-testid="slide-image"
          src={props.images[activeIndex]}
          sizes="(max-width: 1200px) 1024px, 1600px"
          alt={props.superFeatureProperties[activeIndex]?.displayAddress}
          srcSet={
            props.highResImages
              ? `${props.images[activeIndex]} 1024w, ${props.highResImages[activeIndex]} ${props.highResThreshold}w`
              : undefined
          }
        />

        <ImageBox
          key={nextIndex}
          active={false}
          data-testid="slide-image"
          src={props.images[nextIndex]}
          sizes="(max-width: 1200px) 1024px, 1600px"
          alt={props.superFeatureProperties[nextIndex]?.displayAddress}
          srcSet={
            props.highResImages
              ? `${props.images[nextIndex]} 1024w, ${props.highResImages[nextIndex]} ${props.highResThreshold}w`
              : undefined
          }
        />

        {props.superFeatureProperties.map((item, index) => (
          <ImageInfoBar key={index} superFeatureProperty={item} active={index === activeIndex} />
        ))}

        {props.children}
      </Content>
    </Container>
  );
};

export default ImageSlideshow;
